import { defaultCookieSettings } from '@/components/CTA/Cta';
import Cookies from 'js-cookie';
import { useContext, useRef } from 'react';
import { InterestsContext } from '../contexts/Interests';
import { useOmnisearchState } from "../contexts/OmnisearchState";
import { useSettings } from '../contexts/Settings';
import StepEnum from '../enum';
import InterestsModel from './InterestsModel';

const useInterests = () => {
    const {
        withActivityInterests,
        viewedCookieName,
        ctaCookieName,
        getIsMobile
    } = useSettings();
    const serviceCookieName = 'cn-FuckYouBusinessTeam';

    const { data, resetValidationError, ctaRef, ...restContextProps } = useContext(InterestsContext);
    const { step, interests } = useOmnisearchState();

    const [state, setState] = interests;
    const [currentStep, setStepState] = step;

    const isDataAvailable = () => withActivityInterests && (data.length > 0);

    const saveInterestsViewed = () => {
        const { value, options } = defaultCookieSettings;

        Cookies.set(viewedCookieName, value, options);
        Cookies.set(ctaCookieName, value, options);

        ctaRef.current?.();
    };

    const onOpen = () => {
        setStepState(StepEnum.INTERESTS);
        saveInterestsViewed();
        ctaRef.current?.();
    };

    const onClose = () => {
        setStepState();
    };

    const areBusinessTeamMotherfuckers = () =>
        Boolean(Cookies.get(serviceCookieName));

    const clearMotherfuckingState = () =>
        Cookies.remove(serviceCookieName, { path: '' });

    const setMotherfuckingState = () =>
        Cookies.set(serviceCookieName, 1);

    const isInterestsValid = () => {
        if (isDataAvailable() &&
            !Cookies.get(viewedCookieName) &&
            !getIsMobile()) {
            setMotherfuckingState();

            setTimeout(onOpen, 100);
            return false;
        }

        return true;
    };

    const onChange = interestItem => {
        resetValidationError();

        setState(interests => {
            let isInterestInCollection = false;

            const computedInterests = interests.filter(interestModel => {
                const isEqual = Object.is(
                    interestModel.displayName,
                    interestItem.display_name
                );

                if (isEqual && !isInterestInCollection) {
                    isInterestInCollection = isEqual;
                }

                return !isEqual;
            });

            if (isInterestInCollection) {
                return computedInterests;
            }

            return [
                ...computedInterests,
                new InterestsModel(interestItem)
            ];
        });
    };

    const isSelected = interest => {
        for (const interestModel of state) {
            if (Object.is(
                interestModel.displayName,
                interest.display_name
            )) {
                return true;
            }
        }

        return false;
    };

    const reset = () => {
        setState([]);
    };

    const isStateSelected = () => state.length > 0;


    return {
        ...restContextProps,
        resetValidationError,
        state,
        onChange,
        data,
        currentStep,
        onOpen,
        onClose,
        isSelected,
        ctaRef,
        reset,
        isStateSelected,
        isDataAvailable,
        isInterestsValid,
        areBusinessTeamMotherfuckers,
        clearMotherfuckingState
    };
};

export default useInterests;
